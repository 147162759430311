import { type ICoreSliceState } from "../../types/core";

export const getInitialState = (): ICoreSliceState => ({
  firebase: {
    isInitialized: false,
    isInitializationInProgress: false,
  },
  authentication: {
    initialCheckComplete: false,
    isLoading: false,
    user: null,
    verified: false,
  },
});

import { LoadingButton } from "@mui/lab";
import { DialogActions } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";

interface FormActionsProps {
  onClose: () => void;
  loading: boolean;
}
const FormActions = ({ onClose, loading }: FormActionsProps) => {
  const [t] = useTranslation();

  return (
    <DialogActions>
      <LoadingButton onClick={onClose} loading={loading}>
        {t(Translations.MODAL_CONFIRMATION_BUTTON_CANCEL)}
      </LoadingButton>
      <LoadingButton type="submit" loading={loading}>
        {t(Translations.MODAL_CONFIRMATION_BUTTON_CONFIRM)}
      </LoadingButton>
    </DialogActions>
  );
};

export default FormActions;

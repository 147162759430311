import { Dialog, DialogTitle } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import { dispatch, useAppSelector } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import FormBody from "./FormBody";
import FormActions from "./FormActions";
import React, { useCallback, useEffect } from "react";
import { coreMiddleware, coreSelector } from "store/slices/core";
import { useNavigate } from "react-router-dom";

export interface SignOutModalProps {}

const SignOutModal = (props: SignOutModalProps) => {
  const [t] = useTranslation();
  const { isLoading } = useAppSelector(coreSelector.authentication);
  const { user } = useAppSelector(coreSelector.authentication);
  const navigate = useNavigate();

  const handleClose = useCallback(
    (_?: any, reason?: string) => {
      if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown")) {
        return;
      }
      dispatch(viewsMiddleware.closeModal(ModalName.SignOutModal));
    },
    [isLoading],
  );

  useEffect(() => {
    if (!user) {
      navigate("/home");
      handleClose();
    }
  }, [handleClose, navigate, user]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // dispatch(coreMiddleware.signOut());
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit,
        sx: { pl: 2, pr: 2 },
      }}
    >
      <DialogTitle>{t(Translations.MODAL_SIGN_OUT_TITLE)}</DialogTitle>
      <FormBody />
      <FormActions onClose={handleClose} />
    </Dialog>
  );
};

export default SignOutModal;

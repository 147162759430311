/* eslint-disable @typescript-eslint/no-extraneous-class */
import { type FirebaseOptions, initializeApp, type FirebaseApp } from "firebase/app";
import {
  getAuth,
  type Auth,
  createUserWithEmailAndPassword,
  type UserCredential,
  signInWithEmailAndPassword,
  connectAuthEmulator,
  type User,
} from "firebase/auth";
import { getEnvironmentVariables } from "utils/getEnvironmentVariables";
import { logCall, logDebug, logError, logReject, logResponse } from "utils/logger";
import { type AppCheck, initializeAppCheck, ReCaptchaEnterpriseProvider, CustomProvider } from "firebase/app-check";
import {
  getFunctions,
  connectFunctionsEmulator,
  type Functions,
  httpsCallable,
  type HttpsCallableResult,
} from "firebase/functions";
import { Endpoint, ApiModules } from "./types";
import {
  type ExampleModuleGetExampleBody,
  type ExampleModuleGetExampleResponse,
} from "./types/example/exampleEndpointTypeDef";
import { encrypt, decrypt } from "utils/crypto";

const {
  FIREBASE_CONFIG,
  FIREBASE_RECAPTCHA_KEY,
  EMULATOR_HOST,
  EMULATOR_FUNCTIONS_PORT,
  EMULATOR_AUTH_PORT,
  EMULATOR_RECAPTCHA_KEY,
} = getEnvironmentVariables();

const customProvider = new CustomProvider({
  getToken: async () => {
    return await Promise.resolve({
      token: "fake-token",
      expireTimeMillis: Date.now() + 1000 * 60 * 60 * 24,
    });
  },
});

export default class FirebaseManager {
  private static app: FirebaseApp;
  private static auth: Auth;
  private static appCheck: AppCheck;
  private static reCaptcha: ReCaptchaEnterpriseProvider;
  private static functions: Functions;
  private static createCallableFunction<B, R>(module: string, functionName: string) {
    return async (body?: B) => {
      const cloudFunction = httpsCallable<string | B, string | R>(this.functions, functionName);
      try {
        const encryptedBody = encrypt(body);
        logCall(`${module}:${functionName}`, body);
        const response = await cloudFunction(encryptedBody);
        const data: R = decrypt(response.data as string);
        const clonedResponse = JSON.parse(JSON.stringify(response));
        clonedResponse.data = data;
        logResponse(`${module}:${functionName}`, body, clonedResponse);
        return clonedResponse as HttpsCallableResult<R>;
      } catch (error) {
        logReject(`${module}:${functionName}`, body, error);
        throw error;
      }
    };
  }

  public static API = {
    ExampleModule: {
      [Endpoint.example]: this.createCallableFunction<ExampleModuleGetExampleBody, ExampleModuleGetExampleResponse>(
        ApiModules.ExampleModule,
        Endpoint.example,
      ),
    },
  };

  public static initializeFirebaseApplication(): void {
    this.initializeApp();
    this.initializeAuthentication();
    this.initializeAppCheck();
    this.initializeFunctions();
  }

  private static initializeApp(): void {
    if (this.app) {
      return;
    }
    try {
      const stringifiedJSON = window.atob(FIREBASE_CONFIG);
      const firebaseConfig: FirebaseOptions = JSON.parse(stringifiedJSON);
      this.app = initializeApp(firebaseConfig);
      logDebug("App Initialized", this.app);
    } catch (error) {
      logError("App initialization Failed");
    }
  }

  private static initializeAuthentication(): void {
    if (this.auth) {
      return;
    }
    try {
      this.auth = getAuth(this.app);
      if (EMULATOR_HOST && EMULATOR_AUTH_PORT) {
        connectAuthEmulator(this.auth, `http://${EMULATOR_HOST}:${EMULATOR_AUTH_PORT}`, {
          disableWarnings: true,
        });
      }
      logDebug("App Auth Initialized", this.auth);
    } catch (error) {
      logError("Firebase Auth initialization failed", error);
    }
  }

  private static initializeReCaptcha(): void {
    if (this.reCaptcha) {
      return;
    }
    try {
      this.reCaptcha = new ReCaptchaEnterpriseProvider(EMULATOR_RECAPTCHA_KEY ?? FIREBASE_RECAPTCHA_KEY);
      logDebug("App ReCaptcha Initialized", this.reCaptcha);
    } catch (error) {
      logError("ReCaptcha initialization failed");
    }
  }

  private static initializeAppCheck(): void {
    if (this.appCheck) {
      return;
    }
    try {
      if (EMULATOR_HOST) {
        this.appCheck = initializeAppCheck(this.app, {
          provider: customProvider,
          isTokenAutoRefreshEnabled: true,
        });
      } else {
        this.initializeReCaptcha();
        this.appCheck = initializeAppCheck(this.app, { provider: this.reCaptcha, isTokenAutoRefreshEnabled: true });
      }
      logDebug("App Check Initialized", this.appCheck);
    } catch (error) {
      logError("App Check initialization failed", error);
    }
  }

  private static initializeFunctions(): void {
    if (this.functions) {
      return;
    }
    try {
      this.functions = getFunctions(this.app);
      if (EMULATOR_HOST && EMULATOR_FUNCTIONS_PORT) {
        connectFunctionsEmulator(this.functions, `${EMULATOR_HOST}`, +EMULATOR_FUNCTIONS_PORT);
      }
      logDebug("Cloud Functions Initialized", this.functions, this.API);
    } catch (error) {
      logError("Cloud Functions initialization failed", error);
    }
  }

  public static async registerUser(email: string, password: string): Promise<UserCredential> {
    return await createUserWithEmailAndPassword(this.auth, email, password);
  }

  public static async signInUser(email: string, password: string): Promise<UserCredential> {
    return await signInWithEmailAndPassword(this.auth, email, password);
  }

  public static async signOutUser(): Promise<void> {
    await this.auth.signOut();
  }

  public static getUser(): User | null {
    return this.auth.currentUser;
  }
}

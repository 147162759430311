/* eslint no-process-env: 0 */
export const getEnvironmentVariables = (): Record<string, string> => ({
  NODE_ENV: process.env.NODE_ENV,
  FIREBASE_CONFIG: process.env.REACT_APP_FIREBASE_CONFIG!,
  FIREBASE_RECAPTCHA_KEY: process.env.REACT_APP_FIREBASE_RECAPTCHA_KEY!,
  EMULATOR_HOST: process.env.REACT_APP_EMULATOR_HOST!,
  EMULATOR_AUTH_PORT: process.env.REACT_APP_EMULATOR_AUTH_PORT!,
  EMULATOR_FUNCTIONS_PORT: process.env.REACT_APP_EMULATOR_FUNCTIONS_PORT!,
  EMULATOR_FIRESTORE_PORT: process.env.REACT_APP_EMULATOR_FIRESTORE_PORT!,
  EMULATOR_RECAPTCHA_KEY: process.env.REACT_APP_EMULATOR_RECAPTCHA_KEY!,
  ENCRYPTION_ALGORITHM: process.env.REACT_APP_ENCRYPTION_ALGORITHM!,
  ENCRYPTION_KEY: process.env.REACT_APP_ENCRYPTION_KEY!,
  IV_KEY: process.env.REACT_APP_IV_KEY!,
});

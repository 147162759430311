import i18next, { type Resource, type InitOptions } from "i18next";
import enJSON from "../assets/i18n/en.json";
import ruJSON from "../assets/i18n/ru.json";
import { initReactI18next } from "react-i18next";
import { type Translations } from "constants/translations";

type LocalizationJSON = Record<Translations, string>;

const i18nOptions: InitOptions = {
  resources: {
    en: {
      translation: enJSON as LocalizationJSON,
    },
    ru: {
      translation: ruJSON as LocalizationJSON,
    },
  },
  lng: "en",
  fallbackLng: "en",
};

const initI18n = async (additionalResources: Resource = {}) => {
  await i18next.use(initReactI18next).init({ ...i18nOptions, ...additionalResources });
};

initI18n();

import { type SliceCaseReducers } from "@reduxjs/toolkit";
import { type User } from "firebase/auth";
import { type IAction } from "store/store";
import { type ICoreSliceState } from "store/types/core";

const createReducer = <T extends SliceCaseReducers<ICoreSliceState>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setAuthUser(state, action: IAction<User | null>) {
    state.authentication = {
      ...state.authentication,
      user: action.payload,
    };
  },
  setIsVerified(state, action: IAction<boolean>) {
    state.authentication.verified = action.payload;
  },
  setAuthLoading(state, action: IAction<boolean>) {
    state.authentication = {
      ...state.authentication,
      isLoading: action.payload,
    };
  },
  setIsFirebaseInitializationInProgress(state, action: IAction<boolean>) {
    state.firebase = {
      ...state.firebase,
      isInitializationInProgress: action.payload,
    };
  },
  setIsFirebaseInitialized(state, action: IAction<boolean>) {
    state.firebase = {
      ...state.firebase,
      isInitialized: action.payload,
    };
  },
  setFirebaseAuthInitialState(state) {
    state.authentication = {
      ...state.authentication,
      initialCheckComplete: true,
    };
  },
});

export default reducers;

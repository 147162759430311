import React, { useEffect } from "react";
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import "./utils/i18n";
import HomeLayout from "layouts/HomeLayout";
import RootLayout from "layouts/RootLayout";
import HomePage from "pages/Home";
import NotFoundPage from "pages/NotFound";
import { dispatch, useAppSelector } from "store/hooks";
import { coreMiddleware, coreSelector } from "store/slices/core";
import GlobalLoading from "components/GlobalLoading";

const App = () => {
  const firebase = useAppSelector(coreSelector.firebase);
  const authentication = useAppSelector(coreSelector.authentication);
  const { isInitialized } = firebase;
  const { initialCheckComplete } = authentication;

  const initializationComplete = isInitialized && initialCheckComplete;

  useEffect(() => {
    if (!firebase.isInitialized && !firebase.isInitializationInProgress) {
      dispatch(coreMiddleware.initialize());
    }
  }, [firebase]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootLayout />}>
        <Route index element={<Navigate to={authentication.user ? "/dashboard" : "/home"} replace />} />
        <Route path="home" element={<HomeLayout />}>
          <Route index element={<HomePage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Route>,
    ),
  );

  return <>{initializationComplete ? <RouterProvider router={router} /> : <GlobalLoading show />}</>;
};

export default App;

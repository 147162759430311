import { createSelector } from "@reduxjs/toolkit";
import { type RootState } from "store/store";

const selector = (state: RootState) => state.core;

const authentication = createSelector([selector], (state) => state.authentication);
const firebase = createSelector([selector], (state) => state.firebase);

export default {
  authentication,
  firebase,
};

import { Dialog, DialogTitle } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import { dispatch, useAppSelector } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import FormBody from "./FormBody";
import FormActions from "./FormActions";
import React, { useCallback, useEffect } from "react";
import { coreSelector } from "store/slices/core";
import { useNavigate } from "react-router-dom";

export interface SignInModalProps {}

const SignInModal = (props: SignInModalProps) => {
  const [t] = useTranslation();
  const { isLoading, user } = useAppSelector(coreSelector.authentication);
  const navigate = useNavigate();

  const handleClose = useCallback(
    (_?: any, reason?: string) => {
      if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown")) {
        return;
      }
      dispatch(viewsMiddleware.closeModal(ModalName.SignInModal));
    },
    [isLoading],
  );

  useEffect(() => {
    if (!!user) {
      navigate("/dashboard");
      handleClose();
    }
  }, [handleClose, user, navigate]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // event.preventDefault();
    // const form: ISignInBody = getFormData(event);
    // dispatch(coreMiddleware.signIn(form));
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit,
      }}
    >
      <DialogTitle>{t(Translations.MODAL_SIGN_IN_TITLE)}</DialogTitle>
      <FormBody />
      <FormActions onClose={handleClose} />
    </Dialog>
  );
};

export default SignInModal;

import React from "react";
import { useAppSelector } from "store/hooks";
import { viewsSelector } from "store/slices/views";
import { type IOpenedModal, ModalName } from "store/types/views";
import SignInModal, { type SignInModalProps } from "../User/SignInModal";
import SignOutModal, { type SignOutModalProps } from "../User/SignOutModal";
import ConfirmationModal, { type ConfirmationModalProps } from "../shared/ConfirmationModal";

const getSignInModal = (modal: IOpenedModal<SignInModalProps>) => <SignInModal key={modal.name} {...modal.props} />;
const getSignOutModal = (modal: IOpenedModal<SignOutModalProps>) => <SignOutModal key={modal.name} {...modal.props} />;
const getConfirmationModal = (modal: IOpenedModal<ConfirmationModalProps>) => (
  <ConfirmationModal key={modal.name} {...modal.props} />
);

export const ModalsController = () => {
  const modals = useAppSelector(viewsSelector.modals);

  return (
    <>
      {modals.map((modal: IOpenedModal) => {
        switch (modal.name) {
          case ModalName.SignInModal:
            return getSignInModal(modal);
          case ModalName.SignOutModal:
            return getSignOutModal(modal);
          case ModalName.ConfirmationModal:
            return getConfirmationModal(modal);
          default:
            return null;
        }
      })}
    </>
  );
};

import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const HomeLayout = () => {
  return (
    <>
      <Box sx={{ pt: "40px" }}>
        <Outlet />
      </Box>
    </>
  );
};

export default HomeLayout;

import store, { type AppDispatch } from "store/store";
import slice from "./slice";
import FirebaseManager from "dataLayer/FirebaseManager";
import { logError } from "utils/logger";
import { getAuth, onAuthStateChanged, type User } from "firebase/auth";
import { viewsMiddleware } from "../views";
import { ModalName, SeveritiesType } from "store/types/views";
import { getApp } from "firebase/app";
import { Translations } from "constants/translations";

const {
  setIsFirebaseInitialized,
  setIsFirebaseInitializationInProgress,
  setAuthUser,
  setIsVerified,
  setFirebaseAuthInitialState,
} = slice.actions;

const initialize = () => async (dispatch: AppDispatch) => {
  if (store.getState().core.firebase.isInitializationInProgress) {
    return;
  }
  try {
    setIsFirebaseInitializationInProgress(true);
    FirebaseManager.initializeFirebaseApplication();
    const app = getApp();
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user: User | null) => {
      // dispatch(applyUserData(user));
    });
    dispatch(setIsFirebaseInitialized(true));
  } catch (error) {
    logError("Firebase Initialization Failed", error);
  } finally {
    dispatch(setIsFirebaseInitializationInProgress(false));
  }
};

// const signIn = (data: ISignInBody) => async (dispatch: AppDispatch) => {
//   try {
//     dispatch(setAuthLoading(true));
//     await FirebaseManager.signInUser(data.email, data.password);
//   } catch (error) {
//     dispatch(signOut(true));
//     logError("Sign In", error);
//     let message: string = Translations.TOAST_ERROR_SIGN_IN_USER_DISABLED;
//     let messageObject;

//     switch ((error as FirebaseError).code) {
//       case FirebaseAuthError.SIGN_IN_WRONG_PASSWORD:
//         message = Translations.TOAST_ERROR_SIGN_IN_WRONG_PASSWORD;
//         break;
//       case FirebaseAuthError.SIGN_IN_WRONG_EMAIL:
//         message = Translations.TOAST_ERROR_SIGN_IN_NOT_REGISTERED;
//         messageObject = {
//           email: data.email,
//         };
//         break;
//       case FirebaseAuthError.SIGN_IN_USER_DISABLED:
//         message = Translations.TOAST_ERROR_SIGN_IN_USER_DISABLED;
//         messageObject = {
//           email: data.email,
//         };
//         break;

//       default:
//         break;
//     }
//     dispatch(
//       viewsMiddleware.setToastNotificationPopUpState({
//         open: true,
//         props: {
//           severityType: SeveritiesType.error,
//           message,
//           messageObject,
//         },
//       }),
//     );
//   } finally {
//     dispatch(setAuthLoading(false));
//   }
// };

// const signOut =
//   (silent: boolean = false) =>
//   async (dispatch: AppDispatch) => {
//     try {
//       dispatch(setAuthLoading(true));
//       await FirebaseManager.signOutUser();
//       dispatch(setAuthUser(null));
//       if (!silent) {
//         dispatch(
//           viewsMiddleware.setToastNotificationPopUpState({
//             open: true,
//             props: {
//               severityType: SeveritiesType.success,
//               message: Translations.TOAST_SUCCESS_SIGN_OUT,
//             },
//           }),
//         );
//       }
//     } catch (error) {
//       dispatch(
//         viewsMiddleware.setToastNotificationPopUpState({
//           open: true,
//           props: {
//             severityType: SeveritiesType.error,
//             message: Translations.TOAST_ERROR_SIGN_OUT,
//           },
//         }),
//       );
//       logError("Sign out failed", error);
//     } finally {
//       dispatch(setAuthLoading(false));
//     }
//   };

// const applyUserData =
//   (user = FirebaseManager.getUser()) =>
//   async (dispatch: AppDispatch) => {
//     try {
//       if (user) {
//         const idToken = await user.getIdTokenResult();
//         const isVerified = idToken.claims.role === "expectedRole";
//         dispatch(setIsVerified(isVerified));
//         if (isVerified) {
//           dispatch(setAuthUser(user));
//           dispatch(
//             viewsMiddleware.setToastNotificationPopUpState({
//               open: true,
//               props: {
//                 severityType: SeveritiesType.success,
//                 message: Translations.TOAST_SUCCESS_SIGN_IN,
//               },
//             }),
//           );
//           dispatch(viewsMiddleware.closeModal(ModalName.SignInModal));
//         } else {
//           dispatch(signOut(true));
//         }
//       } else {
//         dispatch(setAuthUser(null));
//         dispatch(
//           viewsMiddleware.setToastNotificationPopUpState({
//             open: true,
//             props: {
//               severityType: SeveritiesType.success,
//               message: Translations.TOAST_SUCCESS_SIGN_OUT,
//             },
//           }),
//         );
//       }
//     } catch (error) {
//       dispatch(signOut());
//       dispatch(
//         viewsMiddleware.setToastNotificationPopUpState({
//           open: true,
//           props: {
//             severityType: SeveritiesType.error,
//             message: Translations.TOAST_ERROR_SIGN_IN,
//           },
//         }),
//       );
//     } finally {
//       dispatch(setFirebaseAuthInitialState());
//     }
//   };

export default { initialize, setIsFirebaseInitialized };
